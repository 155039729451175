export const environment = {
  production: true,
  apiurl: 'https://api.dev.kotanapp.com',
  region: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_HHRUN41oS',
  userPoolWebClientId: '217kuq6t22qpbn4p166bm5e5i3',
  userPoolDomain: 'kotan-auth-stage.auth.ap-northeast-1.amazoncognito.com',
  ssoCallBackUrl: 'https://dev.kotanapp.com/',
  ssoSignOutUrl: 'https://www-stg.technium.net/mymachine/',
  state: 'test1234',
  adminRole: 'Admin,Manager,Service', // 呼び出す時 split(',') 必須
};
